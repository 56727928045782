<template>
  <v-card>
    <v-card-title style='position: relative;'>Cards
      <div class='export-btn' style='position: absolute;right: 0px;'>
        <div class="text-center col-md-3" >
          <v-btn
            color="primary"
            block
            @click="ExportData"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon> Export
          </v-btn>
        </div>
      </div>
      <div class='clear'></div>
    </v-card-title>
    <div>
      <v-card-text>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              @keyup="searchData"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="liveFromDatePickerShow"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="created_from"
                  label="Create From"
                  v-bind="attrs"
                  single-line
                  hide-details
                  dense
                  outlined
                  v-on="on"
                  @change="createdFromData"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="created_from"
                :max="created_to"
                @input="liveFromDatePickerShow = false"
                @change="createdFromData"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="liveToDatePickerShow"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="created_to"
                  label="Created To"
                  v-bind="attrs"
                  single-line
                  hide-details
                  dense
                  outlined
                  v-on="on"
                  @change="createdToData"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="created_to"
                :min="created_from"
                @input="liveToDatePickerShow = false"
                @change="createdToData"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        class="table-kitchen-sink"
      >
        <!-- Image -->
        <template v-slot:item.image_url="{ item }">
          <v-img
            contain
            height="64"
            max-width="64"
            :src="item.image_url"
          ></v-img>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="deleteItemConfirmation(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>

          <!-- Delete dialog -->
          <c-dialog
            v-model="isDeleteItemDialogVisible"
            @onConfirm="deleteItem"
          >
            Are you sure want to delete '{{ selectedItem.name }}'?
          </c-dialog>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {
	mdiPlus,
	mdiMagnify,
	mdiPencilOutline,
	mdiDeleteOutline,
	mdiDownload,
	mdiDelete,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
	components: {},
	data() {
		return {
			formType: 'addItem',
			selectedItem: {},
			isDeleteItemDialogVisible: false,
			isFormVisible: false,
			liveFromDatePickerShow: false,
			liveToDatePickerShow: false,
			headers: [
				{ text: 'CARD NUMBER', value: 'card_number' },
				{ text: 'MEMBER NAME', value: 'user.name' },
				{ text: 'MEMBER MOBILE NUMBER', value: 'user.mobile_number' },
				{ text: 'POINT BALANCE', value: 'point_balance' },
				{ text: 'LAST SYNC', value: 'last_sync' },
				{ text: 'CREATED DATE', value: 'created_at' },
				{
					text: 'ACTIONS',
					value: 'actions',
					sortable: false,
					width: '150px',
				},
			],
			icons: {
				mdiPlus,
				mdiMagnify,
				mdiPencilOutline,
				mdiDeleteOutline,
				mdiDownload,
				mdiDelete,
			},
		}
	},
	computed: {
		...mapGetters('card', ['items', 'totalItems']),
		options: {
			get() {
				return this.$store.getters['card/options']
			},
			set(value) {
				this.$store.commit('card/SET_OPTIONS', value)
			},
		},
		search: {
			get() {
				return this.$store.getters['card/search']
			},
			set(value) {
				this.$store.commit('card/SET_SEARCH', value)
			},
		},
		created_from: {
			get() {
				return this.$store.getters['card/created_from']
			},
			set(value) {
				this.$store.commit('card/SET_CREATED_FROM', value)
			},
		},
		created_to: {
			get() {
				return this.$store.getters['card/created_to']
			},
			set(value) {
				this.$store.commit('card/SET_CREATED_TO', value)
			},
		},
	},
	watch: {
		options() {
			this.datatable()
		},
	},
	methods: {
		searchData: _.debounce(function fn() {
			this.datatable()
		}, 400),
		ExportData: _.debounce(function fn() {
			this.exportdata()
		}, 400),
		createdFromData: _.debounce(function fn() {
			this.datatable()
		}, 400),
		createdToData: _.debounce(function fn() {
			this.datatable()
		}, 400),
		datatable() {
			this.$store.dispatch('card/datatable')
		},
		exportdata() {
			this.$store.dispatch('card/exportData')
		},
		deleteItemConfirmation(item) {
			this.selectedItem = JSON.parse(JSON.stringify(item))
			this.isDeleteItemDialogVisible = true
		},
		deleteItem() {
			this.$store
				.dispatch('card/destroy', this.selectedItem.card_number)
				.then(response => {
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
					this.datatable()
				})
				.catch(error => {
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
	},
}
</script>
